<template>
<div class=''></div>
</template>

<script>

export default {
data() {
return {
     code:'',

};
},

created() {
     this.getUserInfo();
},
methods: {
    async getUserInfo(){
      this.code = this.$route.query.code;
      let userInfo = sessionStorage.getItem("userInfo");
      console.log(userInfo);
      if(this.code){
        if(!userInfo){
          let res = await this.$http.get('/oauth2/userinfo/get',{code:this.code})
          console.log(res);
          if(res.data.code === 0){
            // let user={
            //   user_id:0,
            //   user_name:'捷护佳',
            //   avatar:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
            //   resource:[
            //    { 
            //       role_name:'运营',
            //       permissions: ["查看K3报表","发送短信"]
            //     }
            //   ]
            // }
             let user={
              user_id:0,
              user_name:'',
              avatar:'',
              resource:[
               { 
                  role_name:'',
                  permissions: []
                }
              ]
            }
            console.log(user);
            user.user_id = res.data.data.user_id
            user.user_name = res.data.data.user_name
            user.avatar = res.data.data.avatar
            user.resource=res.data.data.resource
            sessionStorage.setItem("userInfo",JSON.stringify(user))          
            } else{
                errorAlert(res.data.msg)
              }
                
        let obj=JSON.parse(window.sessionStorage.getItem("userInfo"))
        if(obj){
           let permissions = obj.resource;
            permissions.forEach(item=>{
              if(item.permissions.length>1){
                  this.$router.push('/send-management')
              }else{
                 item.permissions.forEach(per=>{
                 if(per=='查看K3报表'){
                   this.$router.push("/financialStatements")
                 }else{
                    this.$router.push('/send-management')
                 }
               })
              }
              if(item.permissions.length < 1){
                  return this.$router.push("/404")
              }
            })
        }
        }
      }
    },

},
}
</script>
<style lang='scss' scoped>

</style>